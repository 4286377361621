import {Dispatch, forwardRef, SetStateAction, useEffect, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './styles/Unslider.module.scss';
import useSwipe from "../../hooks/useSwipe";
import Button from "./Button";
import FontAwesome from "./FontAwesome";
import classNames from 'classnames';
import isHotkey from "is-hotkey";

export interface UnsliderHandle {
    pause: () => void;
    resume: () => void;
}

const Unslider = forwardRef<UnsliderHandle, {
    children: React.ReactElement[],
    delay?: number,
    containerClassName: string,
    blockId?: string,
    currentIndex: number,
    setCurrentIndex: Dispatch<SetStateAction<number>>,
    slideContainerStyles?: object,
    controlsStyles?: string,
}>((props, ref) => {
    const {delay = 5000} = props;
    const [autoplay, setAutoplay] = useState(true);

    useImperativeHandle(ref, () => ({
        pause: () => setAutoplay(false),
        resume: () => setAutoplay(true),
    }), [autoplay]);

    function handleNext() {
        props.setCurrentIndex(v => (v + 1 === props.children.length ? 0 : v + 1));
    }

    function handlePrev() {
        props.setCurrentIndex(v => (v === 0 ? props.children.length - 1 : v - 1));
    }

    // Keydown function to focus on the element content in the message block carousel
    // after keying "shift-tab". This will execute when the user has paused the carousel
    // and the focus is on the paused tab indicator
    function onKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
        if (!autoplay && props.blockId) {
            const currentEleLabel = document.activeElement?.ariaLabel;
            let slideNumber;
            if (currentEleLabel) {
                slideNumber = currentEleLabel.replace(/[^0-9]/g, '');
            }
            if (isHotkey('shift+tab', e) && slideNumber && (parseInt(slideNumber) === props.currentIndex + 1)) {
                e.preventDefault();
                const slide = document.getElementsByClassName(props.blockId);
                if (slide.length > 0) {
                    (slide[props.currentIndex] as HTMLElement).focus();
                }
            }
        }
    }

    function handleSlideClick(index: number) {
        return () => {
            props.setCurrentIndex(index);
            setAutoplay(false);
        };
    }

    const swipe = useSwipe({ handleRightSwipe: handlePrev, handleLeftSwipe: handleNext });

    useEffect(() => {
        let t: NodeJS.Timeout | null = null;
        if (autoplay) {
            t = setTimeout(handleNext, delay);
        }
        return () => {
            if (t) clearTimeout(t);
        };
    }, [autoplay, props.currentIndex]);

    const containerClassName = classNames(styles.container, props.containerClassName);
    const controlsClassName = classNames(styles.controls, props.controlsStyles);

    return (
        <div ref={swipe} className={containerClassName} onKeyDown={onKeyDown}>
            <div style={props.slideContainerStyles || {}}>
                {props.children}
            </div>
            <div className={controlsClassName} style={{ zIndex: props.children.length + 1 }}>
                <Button
                    onClick={() => setAutoplay(v => !v)}
                    className={styles.pause}
                    aria-label={autoplay ? 'Pause Slideshow' : 'Start Slideshow'}
                >
                    <FontAwesome
                        style={{ textShadow: '0px 0px 6px black' }}
                        prefix={'fas'}
                        name={(autoplay) ? 'fa-pause' : 'fa-play'}
                    />
                </Button>
                {props.children.map((_, i) => {
                    const indicatorClass = classNames(styles.indicator, {
                        [styles.activeHero]: i === props.currentIndex && !props.blockId,
                        [styles.active]: i === props.currentIndex && props.blockId,
                    });
                    return (
                        <button
                                    key={i}
                                    className={indicatorClass}
                                    onClick={handleSlideClick(i)}
                            aria-label={'Slide ' + (i + 1)}
                            tabIndex={0}
                            data-tooltip={'Slide ' + (i + 1)}
                        />
                    );
                })}
            </div>
        </div>
    );
});

Unslider.displayName = 'Unslider';

Unslider.propTypes = {
    delay: PropTypes.number,
};

export default Unslider;
