import {useEffect, useState, MutableRefObject, FC, SetStateAction, PropsWithChildren} from "react";
import useClickOutside from "../../hooks/useClickOutside";
import classNames from 'classnames';
import styles from "./styles/Popup.module.scss";

export function Arrow(props: {
    position: "top" | "bottom" | "left" | "right",
    className?: string,
}) {
    const arrowClassName = classNames({
        [styles.arrow]: true,
        [styles.bottom]: props.position === "bottom",
        [styles.top]: props.position === "top",
        [styles.left]: props.position === "left",
        [styles.right]: props.position === "right",
        [props.className || ""]: props.className,
    })

    return <div className={arrowClassName}/>
}

const Popup: FC<PropsWithChildren<{
    open: boolean,
    setOpen: SetStateAction<any>,
    editable: boolean,
    renderPopup: React.ReactElement,
    popupClassName?: string,
    wrapperClassName?: string,
    arrowPosition: "top" | "bottom" | "left" | "right",
}>> = (props) => {

    const [left, setLeft] = useState<number>(0);

    const ref: MutableRefObject<any> = useClickOutside(() => {
        props.setOpen(false);
    });

    useEffect(() => {
        if (ref?.current) {
            const rect = ref.current?.getBoundingClientRect();
            const l = rect?.width/2 - 150;
            setLeft(l < 0 ? 0 : l);
        }
    }, [props.open]);

    function handleClick(e) {
        e.stopPropagation();
        e.preventDefault();
        if (!props.open && props.editable) {
            props.setOpen(true);
        }
    }

    const wrapperClassName = classNames({
        [styles.container]: true,
        [props.wrapperClassName || ""]: props.wrapperClassName,
    })

    const popupClassName = classNames({
        [styles.popup]: true,
        [props.popupClassName || ""]: props.popupClassName,
    })

    return <div className={wrapperClassName} ref={ref} onClick={handleClick}>
        {props.open && <div style={{left}} className={popupClassName}>
            <Arrow position={props.arrowPosition} />
            {props.renderPopup}
        </div>}
        {props.children}
    </div>
}

export default Popup;
