import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles/image.module.scss';
import filters from './styles/filters.module.scss';
import {buildCssUrlString} from "../../../utils/StringUtilities";

export default class Image extends Component {
    static propTypes = {
        imageUrl: PropTypes.string.isRequired,
        isBackground: PropTypes.bool,
        backgroundType: PropTypes.oneOf(['cover', 'repeatX', 'repeatY']),
        height: PropTypes.string,
        width: PropTypes.string,
        filter: PropTypes.string,
        description: PropTypes.string,
        isWelcomePage: PropTypes.bool,
    };

    static defaultProps = {
        height: '100%',
        width: '100%',
        isBackground: false,
        filter: null,
    };

    static filterMap = {
        'darkGradient': filters.darkGradient,
    };

    render() {
        const { imageUrl, isBackground, backgroundType, filter, description } = this.props;

        let imgTag;
        if (isBackground) {
            const className = classNames({
                [styles.repeatX]: backgroundType === 'repeatX',
                [styles.repeatY]: backgroundType === 'repeatY',
                [styles.cover]: backgroundType === 'cover',
                [styles.coverWelcomePage]: backgroundType === 'cover' && this.props.isWelcomePage,
            });

            const style = {
                backgroundImage: buildCssUrlString(imageUrl),
            };

            let filterObj;
            if (filter) {
                filterObj = <div className={Image.filterMap[filter]}/>;
            }

            imgTag = <div role="presentation" aria-label={description}>
                <div key={imageUrl} className={className} style={style}/>
                {filterObj}
            </div>

        } else {
            imgTag = <img loading={"lazy"} key={imageUrl} src={imageUrl} title={description}/>;
        }

        const containerClassName = classNames({
            [styles.container]: backgroundType !== undefined && backgroundType !== null && backgroundType !== "",
        });

        return (
            <div className={containerClassName}>
                { imgTag }
                { this.props.children }
            </div>
        );

    }
}
